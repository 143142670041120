import React, { Fragment, useCallback } from 'react';
import cl from 'classnames';

import { FileUrl } from '../../../../../types';

import { S3MultipartUploadOnFileUploaded } from '../../../../../main/s3Multipart/hooks/useS3MultipartUpload';

import { Translate } from '../../../../Translate';

import { DropzoneHelper } from '../../../../dropzone/DropzoneHelper';

import { DropzoneFileUrlFieldControlRequiredProps } from './DropzoneFileUrlFieldControl.types';

type DropzoneFileUrlFieldControlProps = {
  onChange: (fileUrl: FileUrl | null) => void;
};

function DropzoneFileUrlFieldControl({
  accept,
  type,
  disabled,
  error,
  errorClassName,
  onChange
}: DropzoneFileUrlFieldControlProps &
  DropzoneFileUrlFieldControlRequiredProps) {
  const handleFileUploaded = useCallback<S3MultipartUploadOnFileUploaded>(
    (id, uploadId, fileUrl) => {
      onChange(fileUrl);
    },
    [onChange]
  );

  const handleRemoveFile = useCallback<() => void>(() => {
    onChange(null);
  }, [onChange]);

  return (
    <Fragment>
      <DropzoneHelper
        type={type}
        disabled={disabled}
        maxFiles={1}
        preventMaxFilesOverload
        withoutTabs
        accept={accept}
        onFileUploaded={handleFileUploaded}
        onRemoveFile={handleRemoveFile}
      />

      {error && (
        <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
          {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
        </p>
      )}
    </Fragment>
  );
}

export default DropzoneFileUrlFieldControl;
