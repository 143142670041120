import {
  FetchItemTagsCacheKey,
  FetchItemTagsFilters,
  FetchItemTagsGqlQuery,
  FetchItemTagsLimit,
  FetchItemTagsPage,
  FetchItemTagsSort
} from '../../itemTagsTypes';
import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import { useFinInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useFinInfiniteIndexQuery';

import {
  INITIAL_ITEM_TAGS_FILTERS,
  INITIAL_ITEM_TAGS_LIMIT,
  INITIAL_ITEM_TAGS_SORT
} from '../../itemTagsConstants';

interface ItemTagsOptions {
  cacheKey: FetchItemTagsCacheKey;
  initialFilters?: FetchItemTagsFilters;
  initialSort?: FetchItemTagsSort;
  initialPage?: FetchItemTagsPage;
  initialLimit?: FetchItemTagsLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
  query: FetchItemTagsGqlQuery;
}

const scope = 'itemTags';

function useItemTags<
  FetchItemTagsItemTagType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  initialFilters = INITIAL_ITEM_TAGS_FILTERS,
  initialSort = INITIAL_ITEM_TAGS_SORT,
  initialLimit = INITIAL_ITEM_TAGS_LIMIT,
  options,
  query
}: ItemTagsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    limitItems
  } = useFinInfiniteIndexQuery<FetchItemTagsItemTagType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    itemTagsData: data,
    itemTags: items,
    itemTagsError: itemsError,
    itemTagsTotalCount: itemsTotalCount,
    itemTagsFetched: isFetched,
    itemTagsLoading: isLoading,
    itemTagsFetchingNextPage: isFetchingNextPage,
    itemTagsIsPlaceholderData: isPlaceholderData,
    itemTagsFilters: currentFilters,
    itemTagsSort: currentSort,
    itemTagsPage: currentPage,
    itemTagsLimit: currentLimit,
    hasNextItemTagsPage: hasNextPage,
    updateAccountCache: updateItemCache,
    loadMoreItemTags: loadMoreItems,
    filterItemTags: filterItems,
    changeItemTagsFilters: changeItemsFilters,
    clearItemTagsFilters: clearItemsFilters,
    sortItemTags: sortItems,
    limitItemTags: limitItems
  };
}

export default useItemTags;
