import React, { useCallback, useEffect, useMemo } from 'react';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import find from 'lodash/find';

import { MultiSelectDataType } from '../../../../helpers/MultiSelect/types';
import { ItemTagsSelectFieldProps } from './ItemTagsSelectField.types';

import {
  FETCH_SELECT_ITEM_TAGS_QUERY,
  FetchSelectItemTagsQueryResponse
} from '../../queries/fetchSelectItemTags.query';

import useItemTags from '../../hooks/useItemTags/useItemTags';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';

import { ItemTagCache } from '../../ItemTagCache';

import {
  MultiSelectField,
  MultiSelectFieldChangeCallbackType
} from '../../../../helpers/FormFields/MultiSelectField';

function ItemTagsSelectField<FormDataType>({
  autoFocus,
  classNamePrefix,
  control,
  disabled,
  i18nLabel,
  i18nPlaceholder,
  inputWrapperClassName,
  name,
  onBlur,
  onChange,
  onMenuClose,
  onMenuOpen,
  openMenuOnFocus,
  initialFilters,
  multi,
  sortable
}: ItemTagsSelectFieldProps<FormDataType>) {
  const {
    itemTags,
    itemTagsError,
    itemTagsLoading,
    filterItemTags,
    hasNextItemTagsPage,
    loadMoreItemTags
  } = useItemTags<FetchSelectItemTagsQueryResponse>({
    cacheKey: ItemTagCache.selectFieldCacheKey(),
    initialLimit: 1000,
    initialFilters,
    query: FETCH_SELECT_ITEM_TAGS_QUERY
  });

  const prevFilters = usePreviousValue(initialFilters);

  useEffect(() => {
    if (prevFilters !== initialFilters) {
      filterItemTags(initialFilters);
    }
  }, [prevFilters, filterItemTags, initialFilters]);

  useEffect(() => {
    if (hasNextItemTagsPage) {
      void loadMoreItemTags();
    }
  }, [hasNextItemTagsPage, loadMoreItemTags]);

  const itemTagsOptions = useMemo<MultiSelectDataType[]>(
    () =>
      map(itemTags, (item) => ({
        label: item.name,
        value: item.id || ''
      })),
    [itemTags]
  );

  const handleOnChange = useCallback<MultiSelectFieldChangeCallbackType>(
    (newItem) => {
      onChange?.(
        isArray(newItem)
          ? map(newItem, (item) =>
              find(itemTags, (tag) => tag.id === item.value)
            )
          : find(itemTags, (tag) => tag.id === newItem.value)
      );
    },
    [itemTags, onChange]
  );

  return (
    <MultiSelectField<FormDataType>
      autoFocus={autoFocus}
      classNamePrefix={classNamePrefix || (itemTagsError ? 'av_error' : 'av')}
      control={control}
      data={itemTagsOptions}
      disabled={disabled}
      emptyValue={undefined}
      error={itemTagsError}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isSearchable
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
      menuPosition="fixed"
      name={name}
      onBlur={onBlur}
      onChange={handleOnChange}
      multi={multi}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      openMenuOnFocus={openMenuOnFocus}
      optionsLoading={itemTagsLoading}
      sortable={sortable}
    />
  );
}

export default ItemTagsSelectField;
