import { formsErrors } from '../../../../../../../locales/keys';

const nameRules = { required: formsErrors.name.required };
const itemCategoryRules = { required: formsErrors.itemCategory.required };
const priceRules = {
  required: formsErrors.price.required,
  min: { value: 0, message: formsErrors.price.min }
};
const tagNameRules = { required: formsErrors.tagName.required };

function useCreateItemTypeValidationRules() {
  return {
    nameRules,
    itemCategoryRules,
    priceRules,
    tagNameRules
  };
}

export default useCreateItemTypeValidationRules;
