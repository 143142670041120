import {
  FetchItemCategoriesGqlQueryType,
  FetchItemCategoriesCacheKey,
  FetchItemCategoriesFilters,
  FetchItemCategoriesSort,
  FetchItemCategoriesPage,
  FetchItemCategoriesLimit
} from '../../itemCategoriesTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_ITEM_CATEGORIES_FILTERS,
  INITIAL_ITEM_CATEGORIES_LIMIT,
  INITIAL_ITEM_CATEGORIES_PAGE,
  INITIAL_ITEM_CATEGORIES_SORT
} from '../../itemCategoriesConstants';

interface FinPaginatedItemCategoriesOptions {
  query: FetchItemCategoriesGqlQueryType;
  cacheKey: FetchItemCategoriesCacheKey;
  initialFilters?: FetchItemCategoriesFilters;
  initialSort?: FetchItemCategoriesSort;
  initialPage?: FetchItemCategoriesPage;
  initialLimit?: FetchItemCategoriesLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
}

const scope = 'itemCategories';

function useFinPaginatedItemCategories<FetchItemCategoriesItem>({
  cacheKey,
  initialFilters = INITIAL_ITEM_CATEGORIES_FILTERS,
  initialSort = INITIAL_ITEM_CATEGORIES_SORT,
  initialPage = INITIAL_ITEM_CATEGORIES_PAGE,
  initialLimit = INITIAL_ITEM_CATEGORIES_LIMIT,
  query,
  options = {}
}: FinPaginatedItemCategoriesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<FetchItemCategoriesItem>({
    cacheKey,
    scope,
    query,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options
  });

  return {
    itemCategoriesData: data,
    itemCategories: items,
    itemCategoriesError: itemsError,
    itemCategoriesErrorMessage: itemsErrorMessage,
    itemCategoriesTotalCount: itemsTotalCount,
    itemCategoriesFetched: isFetched,
    itemCategoriesLoading: isLoading,
    itemCategoriesIsPlaceholderData: isPlaceholderData,
    itemCategoriesFilters: currentFilters,
    itemCategoriesSort: currentSort,
    itemCategoriesPage: currentPage,
    itemCategoriesLimit: currentLimit,
    updateItemCache: updateItemCache,
    filterItemCategories: filterItems,
    changeItemCategoriesFilters: changeItemsFilters,
    clearItemCategoriesFilters: clearItemsFilters,
    sortItemCategories: sortItems,
    paginateItemCategories: paginateItems,
    limitItemCategories: limitItems,
    prefetchItemCategories: prefetchItems
  };
}

export default useFinPaginatedItemCategories;
