import map from 'lodash/map';
import first from 'lodash/first';

import { IconsEnum } from '../../../../assets/icons/types';
import { Currencies } from '../../../../types';

import {
  FETCH_CURRENCY_REATES_QUERY,
  FetchCurrencyRatesQueryResponse
} from '../../queries/fetchCurrencyRates.query';

import { useCurrencyRates } from '../../hooks/useCurrencyRates';

import { Icon } from '../../../../helpers/Icon';
import { Translate } from '../../../../helpers/Translate';
import { NumberHelper } from '../../../../helpers/NumberHelper';

import { CurrencyRateCache } from '../../CurrencyRateCache';
import { currencyRatesKeys } from '../../../../locales/keys';

interface ExchangeRatesListProps {
  currencies: Currencies[];
}

function ExchangeRatesList({ currencies }: ExchangeRatesListProps) {
  const { currencyRates } = useCurrencyRates<FetchCurrencyRatesQueryResponse>({
    initialLimit: 1,
    cacheKey: CurrencyRateCache.indexCacheKey(),
    query: FETCH_CURRENCY_REATES_QUERY
  });

  return (
    <div className="p-2 rounded-md bg-gray-200 dark:bg-gray-800">
      <div className="flex px-1">
        <div className="shrink-0">
          <Icon icon={IconsEnum.INFORMATION_CIRCLE_SOLID} />
        </div>
        <div className="ml-3">
          <div className="text-sm mb-1">
            <strong>
              <Translate id={currencyRatesKeys.exchangeRate} />
            </strong>
          </div>
          {map(currencies, (currency) => (
            <div className="text-sm" key={currency}>
              <NumberHelper value={1} /> {currency} ={' '}
              <NumberHelper
                value={1 / (first(currencyRates)?.data?.[currency] || 1)}
              />{' '}
              {Currencies.USD}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExchangeRatesList;
