import {
  FetchItemTagsFilters,
  ItemTagID,
  ItemTagName
} from '../../itemTagsTypes';
import {
  MultiSelectDataType,
  MultiSelectGroupedDataType
} from '../../../../helpers/MultiSelect/types';

import {
  MultiSelectFieldControlProps,
  MultiSelectFieldProps
} from '../../../../helpers/FormFields/MultiSelectField';
import { IconsEnum } from '../../../../assets/icons/types';
import { GroupBase } from 'react-select';
import { ReactNode } from 'react';
import { MultiSelectOptionalProps } from '../../../../helpers/MultiSelect';

export type ItemTagsSelectChangeCallbackType = (
  value: ItemTagsSelectItemTag | ItemTagsSelectItemTag[]
) => void;

export interface ItemTagsSelectItemTag {
  id: ItemTagID;
  name: ItemTagName;
}

export type ItemTagsSelectFieldProps<T> = {
  itemTagsSelectDefaultValue?: MultiSelectDataType;
  initialFilters?: FetchItemTagsFilters;
  onChange?: ItemTagsSelectChangeCallbackType;
} & Omit<MultiSelectFieldProps<T>, 'onChange'> &
  MultiSelectFieldControlProps<T> &
  MultiSelectOptionalProps;

export const enum ItemTagIdsSelectGroupedItemTypes {
  DEFAULT_SELECT,
  ACTION_SELECT
}

export type ItemTagIdsSelectDataType = MultiSelectDataType & {
  icon?: IconsEnum;
};

export type ItemTagIdsSelectGroupedDataType = {
  label: string;
  options: ItemTagIdsSelectDataType[];
};

export type ItemTagIdsChangeCallbackType = (
  value: ItemTagID | ItemTagID[]
) => void;

export type ItemTagIdsFormatGroupLabelType = (
  group: GroupBase<MultiSelectGroupedDataType> & {
    type?: ItemTagIdsSelectGroupedItemTypes;
  }
) => ReactNode;

export const createNewTagValue = 'create-new-tag';
