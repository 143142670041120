import {
  Currencies,
  DateFilterType,
  FetchItemsCacheKey,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  ID,
  IdFilterType,
  MoneyType
} from '../../types';

export type CurrencyRateID = ID;
export type CurrencyRateBaseCurrency = Currencies;
export type CurrencyRateData = Record<Currencies, MoneyType>;

export type FetchCurrencyRatesFilters = {
  createdAt?: DateFilterType;
  id?: IdFilterType;
  updatedAt?: DateFilterType;
  userId?: IdFilterType;
};

export enum FetchCurrencyRatesSortTypes {
  ID_DESC = 'ID_DESC'
}

export type FetchCurrencyRatesLimit = FetchItemsLimit;
export type FetchCurrencyRatesSort = FetchCurrencyRatesSortTypes[];
export type FetchCurrencyRatesGqlQuery = FetchItemsGqlQuery;
export type FetchCurrencyRatesCacheKey = FetchItemsCacheKey;
