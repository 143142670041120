import { CompanyNanoID } from '../companies/companiesTypes';
import { ID } from '../../types';
import { TeamNanoID } from '../teams/teamsTypes';
import { TaskNanoID } from '../tasks/tasksTypes';

export class ItemTypeCache {
  static showCacheKey() {
    return 'item-type';
  }

  static indexCacheKey() {
    return 'item-types';
  }

  static indexDefaultCacheKey() {
    return 'default-item-types';
  }

  static companyItemTypesCacheKey(companyNanoId: CompanyNanoID) {
    return `company-${companyNanoId}-item-types`;
  }

  static companySelectCacheKey(companyNanoId: CompanyNanoID) {
    return `company-${companyNanoId}-select-item-types`;
  }

  static taskSelectCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-select-item-types`;
  }

  static selectFieldCacheKey() {
    return `select-item-types-field`;
  }

  static indexSelectCacheKey(fieldName: string) {
    return `item-types-select-${fieldName}`;
  }

  static importTasksToProjectItemsCacheKey(importTaskId: ID) {
    return `import-tasks-to-project-items-${importTaskId}`;
  }

  // static mergeCompaniesItemTypesTableFieldCacheKey(
  //   mainCompanyNanoId: TeamNanoID,
  //   secondaryCompanyNanoId: TeamNanoID
  // ) {
  //   return `merge-companies-${mainCompanyNanoId}-${secondaryCompanyNanoId}-item-types-table-field`;
  // }

  static mergeCompanyItemTypesTableFieldCacheKey(companyNanoId: TeamNanoID) {
    return `merge-company-${companyNanoId}-item-types-table-field`;
  }
}
