import { Controller } from 'react-hook-form';
import find from 'lodash/find';

import { CurrencyMultiSelectValueType } from './components/CurrencySelectFieldControl/CurrencySelectFieldControl.types';

import {
  MultiSelectFieldControlProps,
  MultiSelectFieldProps
} from '../MultiSelectField';

import { CurrencySelectFieldControl } from './components/CurrencySelectFieldControl';
import { words } from '../../../locales/keys';
import { useCallback, useMemo } from 'react';
import { useTranslate } from '../../../common/hooks/useTranslate';

function CurrencySelectField<FormDataType>({
  control,
  disabled,
  name,
  i18nLabel,
  i18nPlaceholder,
  labelClassName,
  inputWrapperClassName,
  error,
  defaultValue,
  menuPosition = 'fixed',
  classNamePrefix,
  onChange: handleChange
}: MultiSelectFieldControlProps<FormDataType> &
  MultiSelectFieldProps<FormDataType>) {
  const { t } = useTranslate();

  const options = useMemo(
    () => [
      { value: 'USD', label: t(words.usd) },
      { value: 'EUR', label: t(words.eur) },
      { value: 'GBP', label: t(words.gbp) },
      { value: 'AUD', label: t(words.aud) },
      { value: 'UAH', label: t(words.uah) }
    ],
    [t]
  );

  const onChangeControl = useCallback(
    (value: string) => {
      const changedItem = find(options, (option) => option.value === value);
      handleChange?.(changedItem);
    },
    [handleChange, options]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name },
        fieldState: { error: fieldError }
      }) => (
        <CurrencySelectFieldControl
          data={options}
          defaultValue={defaultValue}
          disabled={disabled}
          error={fieldError?.message || error}
          i18nLabel={i18nLabel}
          i18nPlaceholder={i18nPlaceholder}
          labelClassName={labelClassName}
          multi={false}
          onChange={(value) => {
            onChange(value);
            onChangeControl?.(value);
          }}
          placeholder={i18nLabel}
          value={value as CurrencyMultiSelectValueType}
          name={name}
          classNamePrefix={classNamePrefix || 'av_select'}
          inputWrapperClassName={inputWrapperClassName || 'w-full'}
          isSearchable
          menuPosition={menuPosition}
        />
      )}
    />
  );
}

export default CurrencySelectField;
