import { gql } from 'graphql-request';

import {
  CurrencyRateBaseCurrency,
  CurrencyRateData,
  CurrencyRateID
} from '../currencyRatesTypes';
import { DateType } from '../../../types';

export interface FetchCurrencyRatesQueryResponse {
  id: CurrencyRateID;
  baseCurrency: CurrencyRateBaseCurrency;
  data: CurrencyRateData;
  uuid: never;
  createdAt: DateType;
}

export const FETCH_CURRENCY_REATES_QUERY = gql`
  query CurrencyRates(
    $filters: CurrencyRatesFilters
    $sort: [CurrencyRatesSortEnum!]
    $offset: Int
    $limit: Int
  ) {
    currencyRates(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        baseCurrency
        createdAt
        data
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
