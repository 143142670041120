import { useMemo } from 'react';
import {
  SelectField,
  SelectFieldProps,
  SelectFieldRequiredProps
} from '../SelectField';
import { SelectDefaultValueType, SelectOptionType } from '../../Select';
import {
  FETCH_ITEM_CATEGORIES_QUERY,
  FetchFinItemCategoriesItemCategoryType
} from '../../../main/itemCategories/query/fetchItemCategories.query';

import { useFinPaginatedItemCategories } from '../../../main/itemCategories/hooks/useFinPaginatedItemCategories';

import { FetchItemCategoriesSortTypes } from '../../../main/itemCategories/itemCategoriesTypes';
import { ItemCategoryCache } from '../../../main/itemCategories/ItemCategoryCache';

function ItemCategoriesSelectField<
  FormDataType,
  ValueType = SelectDefaultValueType
>({
  control,
  disabled,
  name,
  i18nLabel,
  i18nPlaceholder,
  labelClassName,
  error
}: Omit<
  SelectFieldProps<FormDataType, ValueType> &
    SelectFieldRequiredProps<FormDataType>,
  'options'
>) {
  const { itemCategories, itemCategoriesFetched } =
    useFinPaginatedItemCategories<FetchFinItemCategoriesItemCategoryType>({
      query: FETCH_ITEM_CATEGORIES_QUERY,
      cacheKey: ItemCategoryCache.indexCacheKey(),
      initialSort: [FetchItemCategoriesSortTypes.CREATED_AT_ASC]
    });

  const itemCategoriesOptions = useMemo<SelectOptionType<string>[]>(
    () =>
      itemCategories.map((cat) => ({
        label: cat.name,
        value: cat.id
      })),
    [itemCategories]
  );

  return (
    <SelectField
      control={control}
      disabled={disabled || !itemCategoriesFetched}
      error={error}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      labelClassName={labelClassName}
      name={name}
      options={itemCategoriesOptions}
    />
  );
}

export default ItemCategoriesSelectField;
