import {
  FetchCurrencyRatesFilters,
  FetchCurrencyRatesSort,
  FetchCurrencyRatesLimit,
  FetchCurrencyRatesGqlQuery,
  FetchCurrencyRatesCacheKey
} from '../../currencyRatesTypes';

import {
  INITIAL_CURRENCY_RATES_FILTERS,
  INITIAL_CURRENCY_RATES_LIMIT,
  INITIAL_CURRENCY_RATES_SORT
} from '../../currencyRatesConstants';

import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';
import { useFinInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useFinInfiniteIndexQuery';

interface CurrencyRatesOptions {
  cacheKey: FetchCurrencyRatesCacheKey;
  query: FetchCurrencyRatesGqlQuery;
  initialFilters?: FetchCurrencyRatesFilters;
  initialSort?: FetchCurrencyRatesSort;
  initialLimit?: FetchCurrencyRatesLimit;
  options?: {
    cacheTime?: number;
  };
}

const scope = 'currencyRates';

function useCurrencyRates<
  CurrencyRateItemType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  query,
  initialFilters = INITIAL_CURRENCY_RATES_FILTERS,
  initialSort = INITIAL_CURRENCY_RATES_SORT,
  initialLimit = INITIAL_CURRENCY_RATES_LIMIT,
  options = {}
}: CurrencyRatesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems
  } = useFinInfiniteIndexQuery<CurrencyRateItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    currencyRatesData: data,
    currencyRates: items,
    currencyRatesError: itemsError,
    currencyRatesTotalCount: itemsTotalCount,
    currencyRatesFetched: isFetched,
    currencyRatesLoading: isLoading,
    currencyRatesFetchingNextPage: isFetchingNextPage,
    currencyRatesIsPlaceholderData: isPlaceholderData,
    currencyRatesFilters: currentFilters,
    currencyRatesSort: currentSort,
    currencyRatesPage: currentPage,
    currencyRatesLimit: currentLimit,
    hasNextCurrencyRatesPage: hasNextPage,
    updateDownloadCache: updateItemCache,
    loadMoreCurrencyRates: loadMoreItems,
    filterCurrencyRates: filterItems,
    clearCurrencyRatesFilters: clearItemsFilters,
    sortCurrencyRates: sortItems,
    limitCurrencyRates: limitItems
  };
}

export default useCurrencyRates;
