import first from 'lodash/first';

import { Currencies } from '../../../../types';
import {
  FETCH_CURRENCY_REATES_QUERY,
  FetchCurrencyRatesQueryResponse
} from '../../queries/fetchCurrencyRates.query';

import { useCurrencyRates } from '../useCurrencyRates';

import { getCurrencyPrefix } from '../../utils/getCurrencyPrefix';
import { CurrencyRateCache } from '../../CurrencyRateCache';

interface FormattedCurrencyRatesOptions {
  currency?: Currencies;
}

function useFormattedCurrencyRates(
  { currency } = {} as FormattedCurrencyRatesOptions
) {
  const { currencyRates, currencyRatesLoading, currencyRatesError } =
    useCurrencyRates<FetchCurrencyRatesQueryResponse>({
      initialLimit: 1,
      cacheKey: CurrencyRateCache.indexCacheKey(),
      query: FETCH_CURRENCY_REATES_QUERY
    });

  const item = first(currencyRates);

  const currencyExchangeRate = item?.data?.[currency] || 1;
  const currencyPrefix = getCurrencyPrefix(currency);

  return {
    currencyRatesError,
    currencyRatesLoading,
    currencyRatesId: item?.id,
    currencyRatesData: item?.data,
    currencyExchangeRate,
    currencyPrefix
  };
}

export default useFormattedCurrencyRates;
