import { Currencies } from '../../types';
import { FetchCurrencyRatesSortTypes } from './currencyRatesTypes';

export const INITIAL_CURRENCY_RATES_FILTERS = {};
export const INITIAL_CURRENCY_RATES_LIMIT = 1000;
export const INITIAL_CURRENCY_RATES_SORT = [
  FetchCurrencyRatesSortTypes.ID_DESC
];

export const baseCurrenciesList = [
  Currencies.EUR,
  Currencies.GBP,
  Currencies.AUD,
  Currencies.UAH
];
