import React from 'react';
import { Controller } from 'react-hook-form';

import { DropzoneFileUrlFieldRequiredProps } from './DropzoneFileUrlField.types';

import {
  DropzoneFileUrlFieldControl,
  DropzoneFileUrlFieldControlRequiredProps
} from './components/DropzoneFileUrlFieldControl';

function DropzoneFileUrlField<T>({
  accept,
  control,
  disabled,
  name,
  error,
  type
}: DropzoneFileUrlFieldRequiredProps<T> &
  DropzoneFileUrlFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange }, fieldState: { error: fieldError } }) => (
        <DropzoneFileUrlFieldControl
          type={type}
          disabled={disabled}
          error={fieldError?.message || error}
          onChange={onChange}
          accept={accept}
        />
      )}
    />
  );
}

export default DropzoneFileUrlField;
