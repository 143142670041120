import {
  FetchItemTypesCacheKey,
  FetchFinItemTypesFilters,
  FetchItemTypesSort,
  FetchItemTypesPage,
  FetchItemTypesLimit,
  FetchItemTypesGqlQuery
} from '../../itemTypesTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_ITEM_TYPES_FILTERS,
  INITIAL_ITEM_TYPES_LIMIT,
  INITIAL_ITEM_TYPES_PAGE,
  INITIAL_ITEM_TYPES_SORT
} from '../../itemTypesConstants';

interface FinPaginatedItemTypesOptions {
  cacheKey: FetchItemTypesCacheKey;
  query: FetchItemTypesGqlQuery;
  initialFilters?: FetchFinItemTypesFilters;
  initialSort?: FetchItemTypesSort;
  initialPage?: FetchItemTypesPage;
  initialLimit?: FetchItemTypesLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
    cacheTime?: number;
  };
}

const scope = 'itemTypes';

function useFinPaginatedItemTypes<FetchItemTypesItemTypeType>({
  cacheKey,
  initialFilters = INITIAL_ITEM_TYPES_FILTERS,
  initialSort = INITIAL_ITEM_TYPES_SORT,
  initialPage = INITIAL_ITEM_TYPES_PAGE,
  initialLimit = INITIAL_ITEM_TYPES_LIMIT,
  query,
  options = {}
}: FinPaginatedItemTypesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<FetchItemTypesItemTypeType>({
    cacheKey,
    scope,
    query,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options
  });

  return {
    itemTypesData: data,
    itemTypes: items,
    itemTypesError: itemsError,
    itemTypesErrorMessage: itemsErrorMessage,
    itemTypesTotalCount: itemsTotalCount,
    itemTypesFetched: isFetched,
    itemTypesLoading: isLoading,
    itemTypesIsPlaceholderData: isPlaceholderData,
    itemTypesFilters: currentFilters,
    itemTypesSort: currentSort,
    itemTypesPage: currentPage,
    itemTypesLimit: currentLimit,
    updateItemTypeCache: updateItemCache,
    filterItemTypes: filterItems,
    changeItemTypesFilters: changeItemsFilters,
    clearItemTypesFilters: clearItemsFilters,
    sortItemTypes: sortItems,
    paginateItemTypes: paginateItems,
    limitItemTypes: limitItems,
    prefetchItemTypes: prefetchItems
  };
}

export default useFinPaginatedItemTypes;
