import { IconsEnum } from '../../../../assets/icons/types';

import { Icon } from '../../../../helpers/Icon';
import { NumberHelper } from '../../../../helpers/NumberHelper';
import { Translate } from '../../../../helpers/Translate';

import { currencyRatesKeys } from '../../../../locales/keys';
import { Currencies, MoneyType } from '../../../../types';

interface PriceInCurrencyAlertMessageProps {
  currency: Currencies;
  value: MoneyType;
}

function PriceInCurrencyAlertMessage({
  currency,
  value
}: PriceInCurrencyAlertMessageProps) {
  return (
    <div className="bg-opacity-30 bg-yellow-300 p-2 rounded-md text-sm">
      <div className="flex px-1">
        <div className="shrink-0">
          <Icon icon={IconsEnum.EXCLAMATION} />
        </div>
        <div className="ml-3">
          <p className="text-yellow-600 dark:text-yellow-200">
            <strong>
              <Translate id={currencyRatesKeys.priceIn} /> {currency}:
            </strong>{' '}
            <span>
              <NumberHelper value={value} />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PriceInCurrencyAlertMessage;
