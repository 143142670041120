import Dinero from 'dinero.js';
import round from 'lodash/round';

function amountDivide(amount: number, divider: number) {
  const dineroAmountDue = Dinero({
    amount: round(amount * 100)
  });

  return dineroAmountDue.divide(divider).getAmount() / 100.0;
}

export default amountDivide;
