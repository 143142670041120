import { useMemo } from 'react';

import {
  CreateItemTypeCacheKeys,
  ItemTypeFields
} from '../../../../../itemTypesTypes';
import {
  CreateItemTypeFormData,
  CreateItemTypeFormResponseType
} from '../../CreateItemTypeForm.types';
import { Currencies } from '../../../../../../../types';
import { CompanyNanoID } from '../../../../../../companies/companiesTypes';

import { useFormattedCurrencyRates } from '../../../../../../currencyRates/hooks/useFormattedCurrencyRates';
import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCreateItemType } from '../../../../../hooks/useCreateItemType';
import { useCreateItemTypeValidationRules } from '../useCreateItemTypeValidationRules';

import { amountDivide } from '../../../../../../../utils/amountDivide';
import { ItemTypeCache } from '../../../../../ItemTypeCache';
import map from 'lodash/map';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { ItemTagsPermissions } from '../../../../../../itemTags/itemTagsConstants';

const defaultItemTypeValues: CreateItemTypeFormData = {
  name: '',
  description: '',
  itemCategoryId: null,
  price: 0,
  viewPrice: 0,
  viewPriceCurrency: Currencies.USD,
  itemTagIds: []
};

interface CreateItemTypeFormOptions {
  afterCreate?: (itemType: CreateItemTypeFormResponseType) => void;
  cacheKeys?: CreateItemTypeCacheKeys;
  companyNanoId?: CompanyNanoID;
}

function useCreateItemTypeForm({
  afterCreate,
  cacheKeys = [],
  companyNanoId
}: CreateItemTypeFormOptions) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    watch
  } = useReactHookForm<CreateItemTypeFormData>({
    defaultValues: defaultItemTypeValues,
    isModalForm: true
  });

  const currentUser = useCurrentUser();

  const isNewItemName = currentUser.hasPermissions(
    ItemTagsPermissions.READ_ITEM_TAGS_FOR_ITEM_NAME_FEATURE
  );

  const {
    createItemTypeReset,
    createItemTypeLoading,
    createItemTypeErrorMessage,
    createItemType
  } = useCreateItemType({
    cacheKeys: [
      ...cacheKeys,
      ItemTypeCache.indexCacheKey(),
      ItemTypeCache.indexDefaultCacheKey()
    ]
  });

  const { nameRules, itemCategoryRules, priceRules } =
    useCreateItemTypeValidationRules();

  const watchCurrency = watch(ItemTypeFields.VIEW_PRICE_CURRENCY);

  const { currencyExchangeRate } = useFormattedCurrencyRates({
    currency: watchCurrency
  });

  const watchItemTagIds = watch(ItemTypeFields.ITEM_TAG_IDS);

  return {
    control,
    resetCreateItemTypeForm: resetForm,
    createItemTypeReset,
    createItemTypeErrorMessage,
    createItemTypeLoading,
    handleCreateItemType: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) => {
        const itemTagIds = data.itemTagIds;

        return createItemType({
          ...data,
          companyNanoId,
          price: amountDivide(+data.price, currencyExchangeRate),
          viewPrice: +data.price,
          viewPriceCurrency: data.viewPriceCurrency,
          itemTypeItemTagsAttributes: map(itemTagIds, (item, position) => ({
            itemTagId: item.value,
            position
          }))
        }).then((res) =>
          afterCreate?.({
            ...res.createItemType.record
          })
        );
      }
    }),
    registerFields: {
      registerName: register(
        ItemTypeFields.NAME,
        isNewItemName ? undefined : nameRules
      ),
      registerItemTagIds: register(
        ItemTypeFields.ITEM_TAG_IDS,
        isNewItemName ? nameRules : undefined
      ),
      registerCategoryId: register(
        ItemTypeFields.ITEM_CATEGORY_ID,
        itemCategoryRules
      ),
      registerPrice: register(ItemTypeFields.PRICE, priceRules),
      registerDescription: register(ItemTypeFields.DESCRIPTION),
      registerImageUrl: register(ItemTypeFields.IMAGE_URL),
      registerNewClientDefaultAt: register(ItemTypeFields.NEW_CLIENT_DEFAULT_AT)
    },
    validationErrors: {
      nameValidationError: errors?.name?.message,
      itemCategoryValidationError: errors?.itemCategoryId?.message,
      priceValidationError: errors?.price?.message
    },
    watchPrice: watch(ItemTypeFields.PRICE),
    watchItemTagIds,
    watchCurrency,
    currencyExchangeRate,
    itemTagsFilters: useMemo(
      () => ({
        companyNanoId: { isNull: true }
      }),
      []
    ),
    isNewItemName
  };
}

export default useCreateItemTypeForm;
